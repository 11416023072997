@import "../Variants.sass"
.phoneNumberConfirmationTitle
  text-align: center
  font-weight: bold
  font-size: 18px
  line-height: 1.4

.phoneNumberWrap
  margin-top: 18px
  border: 2px solid #E7E7E7
  border-radius: 10px
  padding: 22px 28px 18px
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  @media screen and (max-width: 370px)
    padding: 20px 22px 16px

.buttonWrap
  width: 100%

.phoneNumberAlertText
  margin-top: 12px
  font-size: 15px
  font-weight: bold
  @media screen and (max-width: 370px)
    font-size: 14px

.phoneNumberConfirmationText
  margin-top: 15px
  text-align: center
  font-size: 15px
  & > span
    font-weight: bold

.closeTextButton
  margin-top: 5px
  text-align: center
  color: $COLOR_TEXT
  text-decoration: underline
  cursor: pointer
  &:hover
    opacity: 0.5
