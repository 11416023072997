@import "../../Variants.sass"
.paymentDetailLists
  margin-top: 25px
  margin-bottom: 25px
.paymentDetailList
  display: flex
  align-items: flex-start
  justify-content: space-between
  padding: 15px 7px
  color: $COLOR_TEXT
  .detail
    flex: 1
    line-height: 1.4
  .price
    text-align: right
    width: 80px
  & + &
    border-top: 1px solid $COLOR_BORDER

.paymentMethod
  margin-top: 16px

.paymentSummary
  white-space: pre-wrap

.paymentAmount
  font-size: 29px
  font-weight: bold
  margin-top: 6px

.paymentTaxInclude
  margin-top: 7px
  font-size: 16px
  font-weight: bold

.paymentTaxIncludeDetail
  margin-left: 8px

.paymentDetailListHeader
  background: #eee
  padding: 6px 10px
  font-weight: bold
  text-align: center
  margin-top: 15px

.paymentInformation
  margin-top: 16px
