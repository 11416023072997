@import "../Variants.sass"
.component
  text-align: center
  padding: 50px 0 70px 0

.images
  margin-bottom: 10px

.contents
  font-size: 24px
  font-weight: bold
  line-height: 1.4

.description
  font-size: 20px
  line-height: 1.4
  margin-top: 8px

.actions
  margin-top: 12px