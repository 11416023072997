@import "../../Variants.sass"
.list
  padding: 0 17px

.list + .list
  margin-top: 60px

.divider
  height: 1px
  background-color: #D4D4D4
  margin: 50px 0 30px 0

.dividerTitle
  padding: 0 17px
  font-size: 19px
  color: #4f5156
  margin-bottom: 10px
  font-weight: bold