@import "../Variants.sass"

.cardContentLeft
  margin-right: 10px
.cardContents
  display: flex
.cardTitle
  font-size: 19px
  font-weight: bold
  margin-bottom: 4px
.cardDesctiption
  font-size: 16px
  line-height: 1.4
.cardPrice
  font-size: 19px
.cardPriceDevice
  color: $COLOR_PRIMARY
.cardQuantity
  font-size: 19px
  line-height: 1
  margin-top: 12px