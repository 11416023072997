@import "../Variants.sass"
.divider
  height: 1px
  background-color: $COLOR_BORDER
  margin: 20px -15px

.settingsHeader
  padding: 0 15px
  height: 70px
  display: flex
  align-items: center
  box-shadow: 0 1px 4px rgba(#000,0.1)
  .title
    flex: 1
    font-size: 19px
    font-weight: bold
    color: rgba($COLOR_TEXT, 0.8)
  .left
    margin-right: 12px
  .right
    margin-left: 12px
    button + button
      margin-left: 7px

.form
  margin-bottom: 24px
  text-align: left