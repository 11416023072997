@import "../Variants.sass"
.progress
  padding: 20px 0
.progressIndicator
  padding: 40px 0

.errorIconWrap
  display: flex
  justify-content: center
  padding: 30px 0

.errorIcon
  width: 90px

.action
  margin-top: 15px