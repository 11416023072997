@import "../Variants.sass"
.wrap
  display: flex
  align-items: center
  padding: 13px 15px
  boxsizing: border-box
  border: 1px solid #3E92C1
  border-radius: 7px
  background-color: rgba(#3E92C1, 0.05)
  cursor: pointer
  row-gap: 3px
  &:hover
    background-color: rgba(#3E92C1, 0.15)

.titleWrap
  font-size: 16px
  font-weight: bold

.linkText
  margin-top: 2px
  font-size: 14px
  color: #3E92C1
  text-decoration: underline