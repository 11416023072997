$COLOR_PRIMARY: #ED6C00
$COLOR_PRIMARY_LIGHT: #f57c15
$COLOR_WHITE: #ffffff

$COLOR_TEXT: #2D2E31
$COLOR_TEXT_WHITE: rgba(#fff, 0.95)
$COLOR_TEXT_WHITE_SUB: rgba(#fff, 0.8)
$COLOR_BORDER: #D4D4D4

$COLOR_ALERT: #D30101
$COLOR_POSITIVE: #27AE60
$COLOR_NEGATIVE: #E40404

@font-face
  font-family: 'RobotoNumber'
  src: url('/assets/fonts/RobotoNumber.woff2') format('woff2'), url('/assets/fonts/RobotoNumber.woff') format('woff')

$FONT_NUMBER: 'RobotoNumber', 'Roboto', -apple-system, 'BlinkMacSystemFont', sans-serif