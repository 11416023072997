@import "../../Variants.sass"

.shippingAddress
  border-top: 1px solid #EBEBEB
  padding-top: 30px
  margin: 35px 0 54px

.backPageButton
  cursor: pointer
  text-align: center
  margin: 19px 0 47px
.backPageButtonColor
  color: $COLOR_TEXT
  border-bottom: solid 1px $COLOR_TEXT

.confirmPaymentdetail
  font-size: 13px
  margin-top: 10px
  a
    color: $COLOR_PRIMARY

.divider
  height: 1px
  background-color: $COLOR_BORDER
  margin: 20px 0

.billingAddress
  margin-top: 15px
