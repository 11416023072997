@import "../Variants.sass"
$BREAK_MODAL: 520px
.wrap
  z-index: 1000
  position: fixed

.body
  align-items: center
  display: flex
  flex-direction: column
  justify-content: flex-end
  overflow: hidden
  position: fixed
  z-index: 40
  bottom: 0
  left: 0
  right: 0
  top: 0
  &.center
    justify-content: center
  @media (min-width: $BREAK_MODAL) 
    justify-content: center
    

.background
  background-color: rgba(#000, 0.86)
  bottom: 0
  left: 0
  position: absolute
  right: 0
  top: 0

.window
  background-color: #fff
  height: 100%
  position: relative
  width: 100%
  display: flex
  flex-direction: column
  max-width: $BREAK_MODAL - 20px
  @media (min-width: $BREAK_MODAL) 
    height: calc(100% - 80px)
    border-radius: 15px
    overflow: hidden


.header
  z-index: 100
  width: 100%
  padding: 0 17px
  height: 56px
  min-height: 56px
  background-color: #fff
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  box-sizing: border-box
  display: flex
  align-items: center

.headerCloseButton
  width: 30px
  line-height: 1
  position: relative
  z-index: 1
  img
    width: 30px

.bodyWithHeader
  flex: 1
  overflow: auto

.contents
  position: relative
  height: 100%
  flex: 1

.alertWindow
  background-color: #fff
  position: relative
  width: 360px
  max-width: 85%
  padding: 60px 20px
  display: flex
  flex-direction: column
  border-radius: 15px
  text-align: center

.dialogWindow
  background-color: #fff
  position: relative
  width: 360px
  max-width: 90%
  max-height: 92%
  overflow-y: auto
  overflow-x: hidden
  padding: 25px 20px
  display: flex
  flex-direction: column
  border-radius: 15px

.dialogTitle
  font-size: 24px
  font-weight: bold
  text-align: center
  margin-bottom: 18px
  line-height: 1.3

.alertImage
  margin-bottom: 33px

.alertTitle
  font-size: 18px
  font-weight: bold

.alertDescription
  font-size: 16px
  margin-top: 8px


.fullScreenWindow
  background-color: #fff
  position: relative
  width: 100%
  height: 100%

// Modal Animation
.modalEnter
  .window
    transform: translateY(100%)
  .alertWindow
    transform: scale(0.8)
    opacity: 0
  .dialogWindow
    transform: scale(0.8)
    opacity: 0
  .background
    opacity: 0

.modalEnterActive
  .window
    transform: translateY(0)
    transition: 400ms ease
  .alertWindow
    transform: scale(1)
    transition: 200ms ease
  .dialogWindow
    transform: scale(1)
    transition: 200ms ease
    opacity: 1
  .background
    opacity: 1
    transition: 600ms ease

.modalExit
  .window
    transform: translateY(0)
  .dialogWindow
    transform: scale(1)
    transition: 200ms ease
    opacity: 1
  .background
    opacity: 1
.modalExitActive
  pointer-events: none
  .window
    transform: translateY(100%)
    transition: 400ms ease
  .dialogWindow
    transform: scale(0.8)
    opacity: 0
  .background
    opacity: 0
    transition: 600ms ease



.progressIndicator
  text-align: center
  & > .loader
    animation: loadingFadeIn 1.7s ease 0s 1 forwards
    display: inline-block
    opacity: 0
    .spinner
      animation: loadingRotation 1.35s linear infinite
      stroke: $COLOR_PRIMARY
    .circle
      stroke-dasharray: 180
      stroke-dashoffset: 0
      transform-origin: center
      animation: loadingTurn 1.35s ease-in-out infinite

// Animation
@keyframes loadingFadeIn
  20%
    opacity: 0
  100%
    opacity: 0.8

@keyframes loadingRotation
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(270deg)

@keyframes loadingTurn
  0%
    stroke-dashoffset: 180

  50%
    stroke-dashoffset: 45
    transform: rotate(135deg)

  100%
    stroke-dashoffset: 180
    transform: rotate(450deg)
