@import "../Variants.sass"
.wrap
  height: 100%
  padding: 0 17px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-top: -20px

.title
  margin-top: 32px
  color: $COLOR_PRIMARY

.detailText
  margin-top: 8px