@import "../Variants.sass"
.component
  margin: 20px auto 30px auto
  max-width: 710px
  padding: 0 17px

.settings
  display: flex
  flex-direction: column
  row-gap: 10px
  height: 100%

.setting
  border-radius: 10px
  background-color: #f5f5f5
  display: flex
  align-items: center
  padding: 12px 17px

.label
  flex: 1
  font-size: 20px
  font-weight: bold