@import "../../Variants.sass"

.headerLogo
  text-align: center
  margin-bottom: 24px

.passwordForm
  display: block
  margin-top: 20px

.passwordText
  font-size: 15px
  margin-top: 4px

.buttonArea
  margin-top: 35px

.errorText
  font-size: 20px
  color: $COLOR_NEGATIVE
  line-height: 1.4

.errorButton
  margin-top: 40px