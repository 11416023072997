@import "../Variants.sass"
.wrap
  display: inline-block
  line-height: 1
.check
  display: none
.text
  font-weight: bold
  position: relative
  padding-left: 24px
  cursor: pointer
  display: inline-block
  &:hover
    opacity: 0.9
  &:before
    transition: 0.2s ease
    opacity: 0
    position: absolute
    z-index: 2
    top: 6px
    left: 3px
    width: 10px
    height: 4px
    content: ''
    transform: rotate(-45deg) scale(0.5)
    border: 2px solid #fff
    border-top-style: none
    border-right-style: none
  &:after
    content: ''
    position: absolute
    top: 0
    left: 0
    border: 1px solid rgba($COLOR_TEXT,0.4)
    display: block
    width: 16px
    height: 16px
    opacity: 1
    border-radius: 2px
    z-index: 1
    transition: 0.2s ease
  &:hover:after
    border: 1px solid rgba($COLOR_PRIMARY,0.6)
    background-color: rgba($COLOR_PRIMARY,0.1)
.checked
  &:after
    background-color: $COLOR_PRIMARY
    border: 1px solid rgba($COLOR_PRIMARY,0.1)
  &:hover:after
    background-color: rgba($COLOR_PRIMARY,0.9)
  &:before
    opacity: 1
    transform: rotate(-45deg) scale(1)
