@import "../Variants.sass"
.headerWrap
  z-index: 100
  width: 100%
  padding: 0 17px
  height: 56px
  background-color: $COLOR_PRIMARY
  position: fixed
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  box-sizing: border-box
  display: flex
  align-items: center

.offset
  height: 56px

.headerCloseButton
  width: 30px
  line-height: 1
  position: relative
  z-index: 1
  img
    width: 30px
.linkArea
  display: block
    
.headerLogo
  margin-left: -30px
  text-align: center
  font-size: 25px
  line-height: 1
  color: #fff
  flex: 1
  font-weight: bold