@import "../Variants.sass"
.wrap
  margin-bottom: 30px
  display: flex
  align-items: center

.title
  font-size: 28px
  font-weight: bold

.subTitle
  font-size: 22px
  margin-top: 10px
  color: rgba($COLOR_TEXT, 0.9)
  font-weight: bold
  
.backButton
  width: 38px
  height: 38px
  border-radius: 100px
  margin-right: 9px
  background-image: url(/assets/images/icon-back.svg)
  background-repeat: no-repeat
  background-color: #fff
  background-size: 13px 21px
  background-position: left 10px top 7px
  border: 1px solid rgba(#000, 0.1)
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.07), inset 0 0 3px rgba(#fff, 0.1)
  cursor: pointer
  &:hover
    background-color: #eee