@import "../Variants.sass"

.topLogo
  margin: 42px 0 56px
  text-align: center

.action
  margin-top: 14px

.passwordResetText
  color: $COLOR_PRIMARY
  text-align: center
  margin-top: 15px
  font-size: 17px

.checkboxWrap
  margin-top: 10px
  margin-bottom: 20px

.signUpArea
  padding: 25px 50px
  background-color: #F0F2F1
  margin-bottom: 37px

.signUpText
  font-size: 17px
  margin-bottom: 9px
  font-weight: bold
  text-align: center

.signUpButton
  margin-bottom: 7px

.footerLogo
  text-align: center
  margin-bottom: 10px

.copyright
  font-size: 12px
  margin-bottom: 28px
  text-align: center

.field
  & + &
    margin-top: 10px

.separate
  font-size: 15px
  font-weight: bold
  margin-top: 8px
  text-align: center
  display: flex
  align-items: center
  justify-content: space-between

.separateText
  white-space: nowrap
  margin: 0 30px

.separateLine
  content: ''
  height: 1px
  width: 100%
  background-color: #D4D4D4
  color: #D4D4D4

.phoneNumberSigninButtonWrap
  margin-top: 10px
 
.phoneNumberSigninButton
  display: flex
  align-items: center
  justify-content: center

.buttonIcon
  height: 30px
  position: relative
  top: 1px

.buttonText
  margin: 0 2px 0 4px
