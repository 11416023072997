@import "../Variants.sass"
.button
  cursor: pointer
  display: inline-flex
  align-items: center
  outline: none
  vertical-align: top
  margin: 0 0.25em 0 0
  height: 53px
  padding: 0 20px
  font-size: 19px
  text-align: center
  user-select: none
  -webkit-tap-highlight-color: transparent
  -webkit-appearance: none
  color: $COLOR_TEXT
  font-weight: bold
  border-radius: 53px
  border: 1px solid rgba(#000, 0.13)
  box-sizing: border-box
  background-color: #fafbfc
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.07), inset 0 0 3px rgba(#fff, 0.1)
  &:hover
    background-color: #ececec
  &[disabled],
  &.disabled
    cursor: not-allowed
    opacity: 0.2
  &:focus
    box-shadow: 0 0 0 2px rgba($COLOR_PRIMARY, 0.3)

  &.primary
    background-color: $COLOR_PRIMARY
    color: $COLOR_WHITE
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(#fff, 0.1)
    &:hover
      background-color: rgba($COLOR_PRIMARY, 0.9)
    &:focus
      box-shadow: 0 0 0 2px rgba($COLOR_PRIMARY, 0.5)

  &.positive
    background-color: $COLOR_POSITIVE
    color: $COLOR_WHITE
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(#fff, 0.1)
    &:hover
      background-color: rgba($COLOR_POSITIVE, 0.9)
    &:focus
      box-shadow: 0 0 0 2px rgba($COLOR_POSITIVE, 0.5)

  &.negative
    background-color: $COLOR_NEGATIVE
    color: $COLOR_WHITE
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(#fff, 0.1)
    &:hover
      background-color: rgba($COLOR_NEGATIVE, 0.9)
    &:focus
      box-shadow: 0 0 0 2px rgba($COLOR_NEGATIVE, 0.5)

  &.negativeTextOnly
    color: $COLOR_NEGATIVE
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(#fff, 0.1)
    &:hover
      background-color: #ececec
  
  &.sizeSmall
    height: 35px
    font-size: 16px
    padding: 0 15px

  &.sizeLarge
    height: 64px
    font-size: 24px
    padding: 0 70px
  
  &.fit
    width: 100%
    justify-content: center
  
  &.fit + &.fit
    margin-top: 10px



  &.loading
    pointer-events: none
    &:before
      animation: spinAround 500ms infinite linear
      border: 2px solid rgba($COLOR_TEXT,0.3)
      border-radius: 999px
      border-right-color: transparent
      border-top-color: transparent
      content: ""
      display: block
      height: 13px
      position: relative
      width: 13px
      margin-right: 7px
  &.loading.primary,
    &:before
      border: 2px solid rgba(#fff,0.5)
      border-right-color: transparent
      border-top-color: transparent


@keyframes spinAround
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)
