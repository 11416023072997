@import "../Variants.sass"
.wrap
  display: flex
  align-items: center
  justify-content: center
  height: 44px
  width: 44px
  padding: 0
  outline: none
  border: 0
  background: transparent
  user-select: none
  -webkit-tap-highlight-color: transparent
  -webkit-appearance: none
  margin-left: -3px
  &:focus
    outline: none
    .button
      box-shadow: 0 0 2px 3px rgba($COLOR_PRIMARY, 0.5)
.button
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  background-color: #F6F6F6
  width: 38px
  height: 38px
  font-size: 19px
  text-align: center
  color: $COLOR_TEXT
  font-weight: bold
  border-radius: 44px
  box-sizing: border-box
  &:hover
    background-color: #ececec
  .icon
    fill: #555