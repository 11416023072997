@import "../Variants.sass"
.paymentMethodWrap
  display: flex
  align-items: center
  justify-content: space-between
.paymentMethod
  font-size: 19px
  .label
    font-weight: bold
  .detail
    margin-top: 3px

.note
  margin-top: 12px

.divider
  height: 1px
  background-color: $COLOR_BORDER
  margin: 17px 0

.link
  color: $COLOR_PRIMARY
