@import "../Variants.sass"
.wrap
  display: flex
  margin: -13px 0 30px 0

.numberStyle
  color: rgba($COLOR_TEXT, 0.3)
  font-weight: bold
  background-color: #E2E2E2
  width: 25px
  height: 25px
  text-align: center
  border-radius: 50%
  position: relative
  &.current
    background-color: $COLOR_PRIMARY
    color: $COLOR_WHITE
  & + &
    margin-left: 22px
  &::after
    position: absolute
    content: ""
    height: 1px
    width: 10px
    background-color: $COLOR_BORDER
    left: 30px
    bottom: 12px
  &:last-child::after
    content: none
