@import "../../Variants.sass"

.headerLogo
  text-align: center
  margin-bottom: 24px

.buttonArea
  margin-top: 35px

.body
  text-align: center

.text
  margin-top: 20px
  font-size: 24px
  font-weight: bold
  line-height: 1.4

.note
  margin-top: 10px
  font-size: 16px