@import "../../Variants.sass"
.headerLogo
  text-align: center
  margin-bottom: 24px

.inputPhoneNumberTitle
  margin-bottom: 24px
  text-align: center

.detailText
  text-align: center
  margin-top: 10px
  font-size: 18px
  font-weight: bold

.noticeText
  text-align: center
  margin: 19px 0 19px
  color: #ED6C00
  font-size: 18px
  font-weight: bold

.buttonWrap
  margin-top: 28px

.backPageButton
  text-align: center
  margin: 19px 0 47px

.backPageButtonColor
  color: $COLOR_TEXT
  border-bottom: solid 1px $COLOR_TEXT
  &:hover
    cursor: pointer
    opacity: 0.5
 
.backTextButton
  margin-top: 5px
  text-align: center
  color: $COLOR_TEXT
  text-decoration: underline
  cursor: pointer
  &:hover
    opacity: 0.5

.helperTitle
  text-align: center
  font-weight: bold
  font-size: 18px
  line-height: 1.4

.helperImages
  margin: 20px 0 13px
  display: flex
  align-items: flex-start
  justify-content: center

.helperImage
  & + &
    margin-left: 10px

.helperFlowImage
  width: 140px
  border-radius: 2px
  border: 2px solid #E7E7E7
  @media screen and (max-width: 370px)
    width: 120px

.helperFlow
  margin-top: 5px
  display: flex
  align-items: flex-start

.flowNumber
  font-size: 12px
  background-color: $COLOR_PRIMARY
  color: $COLOR_WHITE
  font-weight: bold
  width: 17px
  height: 17px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50px
  position: relative
  top: 3px

.flowText
  margin-left: 3px
  width: 125px
  @media screen and (max-width: 370px)
    width: 105px
