@import "../Variants.sass"
.component
  margin-top: 4px
  display: flex
  align-items: center
  justify-content: space-between
  padding: 2px 10px
  box-sizing: border-box
  background-color: rgba(#fff, 0.3)
  border-radius: 5px
  @media screen and (max-width: 657px)
    padding: 7px 10px
  @media screen and (max-width: 360px)
    padding: 2px
    margin: 8px 5px 0 5px

.routerImage
  height: 45px

.networkImage
  height: 45px

.statusBarWrap
  flex: 1
  height: 44px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  color: $COLOR_WHITE
  margin: 5px 8px 0 8px

.title
  font-size: 10px
  font-weight: bold
  line-height: 1

.statusBar
  width: 100%

.statusDetail
  height: 17px
  display: flex

.status
  content: ""
  width: 100%
  height: 3px
  min-height: 3px
  background-color: $COLOR_WHITE
  margin: 4px 0 4px 0
  border-radius: 10px

.text
  line-height: 1
  font-size: 12px
  font-weight: bold
  
// 完了位アイコンのアニメーション
.iconCheck
  width: 15px
  height: 15px
  animation: fadein 0.3s ease-out forwards
@keyframes fadein 
  0% 
    opacity: 0
  100% 
    opacity: 1
  
// ローディングのアニメーション
.dotIconWrap
  --dot-icon-size: 46px
  --dot-icon-speed: 1.1s
  --dot-icon-color: #fff
  max-height: 4px
  margin: 4px 0
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  width: var(--dot-icon-size)
  height: calc(var(--dot-icon-size) * 0.57)
  @media screen and (max-width: 340px)
    --dot-icon-size: 37px

.dotIcon,
.dotIconWrap::before,
.dotIconWrap::after 
  content: ''
  display: block
  height: calc(var(--dot-icon-size) * 0.05)
  width: calc(var(--dot-icon-size) * 0.065)
  border-radius: 50%
  background-color: var(--dot-icon-color)
  transform: scale(0)

.dotIconWrap::before 
  animation: pulse var(--dot-icon-speed) ease-in-out infinite

.dotIcon 
  animation: pulse var(--dot-icon-speed) ease-in-out calc(var(--dot-icon-speed) * 0.15) infinite both

.dotIconWrap::after 
  animation: pulse var(--dot-icon-speed) ease-in-out calc(var(--dot-icon-speed) * 0.3) infinite

@keyframes pulse 
  0%,100% 
    transform: scale(0)
  50%
    transform: scale(1.5)


