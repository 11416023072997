@import "../../Variants.sass"
.headerLogo
  text-align: center
  margin-bottom: 24px

.errorIconWrap
  display: flex
  justify-content: center
  margin-top: 60px

.errorIcon
  width: 106px

.errorText
  margin-top: 12px
  line-height: 1.5
  font-size: 18px
  text-align: center

.buttonWrap
  margin-top: 40px