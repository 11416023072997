@import "../Variants.sass"
.wrap
  position: relative
  height: 24px
  width: 40px
  display: inline-flex
  align-items: center
  cursor: pointer
  pointer-events: none
.checkbox
  opacity: 0
  width: 0
  height: 0
.slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: #ccc
  -webkit-transition: .4s
  transition: .4s
  border-radius: 40px
  width: 40px

  &:before
    position: absolute
    content: ""
    height: 20px
    width: 20px
    left: 2px
    bottom: 2px
    background-color: white
    transition: .4s
    border-radius: 50%

.checkbox:checked + .slider
  background-color: $COLOR_POSITIVE

.checkbox:checked + .slider:before
  transform: translateX(16px)

.label
  margin-left: 37px
  font-size: 16px