@import "../../Variants.sass"
.orderWrap
  font-size: 19px
  font-weight: bold
  display: flex
  align-items: center

.orderLabel
  width: 80px

.orderValue
  flex: 1
  margin-left: 10px

.orderStatus
  margin-top: 12px
  font-size: 19px
  color: rgba($COLOR_TEXT, 0.8)
  line-height: 1.5
  &.ordered
    color: $COLOR_POSITIVE
  a
    color: $COLOR_PRIMARY

.paymentWrap
  margin-top: 26px

.priceWrap
  display: flex
  justify-content: space-between
  line-height: 1.4
  & + &
    margin-top: 10px

.priceLabel
  flex: 1

.priceValue
  margin-left: 10px
  max-width: 80px

.totalPriceLabel
  font-size: 19px
  font-weight: bold
  flex: 1

.totalPriceValue
  font-size: 19px
  font-weight: bold
  margin-left: 10px

.totalPriceTax
  font-size: 16px
  font-weight: bold
  text-align: right

.totalPriceTaxDetail
  margin-left: 6px

.orderItemsTitle
  font-size: 23px
  font-weight: bold
  line-height: 1.8
  margin-bottom: 12px

.orderItem
  & + &
    border-top: 1px solid $COLOR_BORDER
    margin-top: 20px
    padding-top: 20px

.divider
  height: 1px
  background-color: $COLOR_BORDER
  margin: 20px 0

.customerDetailsTitle
  font-weight: bold
  font-size: 23px
  line-height: 1.8
  margin-bottom: 12px

.customerDetailsWrap
  font-size: 19px

.customerDetailsValue
  & + &
    margin-top: 4px

.customerDetailsText
  color: rgba($COLOR_TEXT, 0.8)

.contactLink
  color: $COLOR_PRIMARY
