@import "../../Variants.sass"
.title
  font-size: 19px
  line-height: 1.4

.text
  font-size: 19px
  color: $COLOR_NEGATIVE
  margin-top: 15px
  font-weight: bold
  line-height: 1.4

.buttonWrap
  margin-top: 50px

.checkboxWrap
  text-align: center
  margin-top: 25px

.checkboxText
  margin-top: 5px
