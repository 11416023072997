@import "../Variants.sass"
.component
  display: flex
  align-items: center
  margin: 0 2px
  padding-top:6px
  margin-top: 8px
  border-top: 1px solid rgba(#fff,0.1)

.value
  display: flex
  color: $COLOR_TEXT_WHITE
  align-items: center
  padding: 2px 2px
  border-radius: 4px
  flex: 1
  .number
    font-size: 48px
    font-family: $FONT_NUMBER
    font-weight: 600
    text-shadow: 0px 0px 4px rgba(#000, 0.25)
    line-height: 1
      
  .unitWrap
    margin-left: 5px
    font-size: 18px
    font-weight: bold
    line-height: 1
    text-shadow: 0px 0px 4px rgba(#000, 0.25)
    .unitText
      font-size: 16px

    .unit
      font-size: 18px
      margin-top: 3px