@import "../../Variants.sass"

.orderNotCompleteText
  color: $COLOR_NEGATIVE
  font-size: 19px
  font-weight: bold
  margin-bottom: 18px

.reviewDeviceArea
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 12px

.divider
  height: 1px
  background-color: $COLOR_BORDER
  margin: 20px 0

.checkoutNote
  font-size: 14px
  margin-top: 10px
  a
    color: $COLOR_PRIMARY

.agreeUserPolicyText
  font-size: 12px
  line-height: 1.6
  margin-top: 13px

.deliveryDateText
  font-weight: bold
  font-size: 18px
  margin: 8px 0
  strong
    color: $COLOR_POSITIVE
    margin-left: 10px

.reviewCreditCard
  margin-top: 10px

.action
  margin-top: 40px

.headerAction
  margin-bottom: 27px