@import "../Variants.sass"
.component
  display: flex
  align-items: center
  margin: 0 2px
  padding-top: 8px
  margin-top: 12px
  border-top: 1px solid rgba(#fff,0.1)

.value
  display: flex
  color: $COLOR_TEXT_WHITE
  align-items: baseline
  padding: 2px 2px
  border-radius: 4px
  position: relative
  top: 1px
  .number
    font-size: 48px
    font-family: $FONT_NUMBER
    font-weight: 600
    text-shadow: 0px 0px 4px rgba(#000, 0.25)
    line-height: 1
      
  .unit
    margin-left: 5px
    font-size: 18px
    font-weight: bold
    line-height: 1
    text-shadow: 0px 0px 4px rgba(#000, 0.25)

.statusWrap
  flex: 1
  margin-left: 14px
  position: relative
  bottom: 7px

.statusLabels
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 4px

.statusLabel
  height: 24px
  display: flex
  align-items: center
  justify-content: center
  background-color: rgba(#fff,0.2)
  color: #0D5B2E
  font-weight: bold
  font-size: 20px
  border-radius: 2px
  &.normal
    background-color: #fff
  &.warning
    background-color: #FFE500
  &.alert
    background-color: #FF1D1D
.statusBarWrap
  background-color: rgba(#fff,0.2)
  height: 3px
  margin-top: 3px
  overflow: hidden
  width: 100%

.statusBarScaleWrap
  position: relative

.statusBar
  background-color: #fff
  height: 3px
  &.warning
    background-color: #FFE500
  &.alert
    background-color: #FF1D1D


.statusBarScale
  text-align: center
  color: #fff
  font-size: 10px
  font-weight: bold
  position: absolute
  line-height: 1
  margin-top: 6px