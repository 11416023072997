@import "../Variants.sass"
.wrap
  display: inline-block
  position: relative
  width: 100%
  &:before
    display: block
    position: absolute
    content: ''
    width: 8px
    height: 8px
    border: 0px
    border-bottom: solid 2px #b4b3b3
    border-right: solid 2px #b4b3b3
    transform: rotate(45deg)
    top: 50%
    right: 12px
    margin-top: -6px
    pointer-events: none

.control
  width: 100%
  height: 53px
  font-size: 21px
  background-color: #fff
  color: $COLOR_TEXT
  outline: none
  border: none
  border: 2px solid #AFAFAF
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15)
  padding: 0 30px 0 10px
  box-sizing: border-box
  appearance: none
  cursor: pointer
  transition: 0.5s ease
  border-radius: 5px

  &:disabled
    background: #ccc
    color: #bbb
  &:hover
    border-color: #888888
  &:focus
    border-color: $COLOR_PRIMARY

.group .control
  border-radius: 4px 0 0 4px
  border-right-width: 1px
.group + .group .control
  border-left-width: 1px
  border-right-width: 2px
  border-radius: 0 4px 4px 0  

.error .control
  border-color: #f00
  color: #f00