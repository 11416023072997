@import "../Variants.sass"

.wrap
  z-index: 100
  background: #fff
  padding: 0 17px
  height: 56px
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  position: fixed
  width: 100%
  box-sizing: border-box

.offset
  height: 56px

.subNavigations
  height: 42px
  height: 42px
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 0 17px
  color: #fff
  display: none

.mainNavigations
  display: flex
  align-items: center
  height: 56px
  max-width: 640px
  margin: 0 auto
  
  .navigation
    flex: 1
    display: flex
    align-items: center
    justify-content: center
    height: 56px
    padding-top: 4px
    box-sizing: border-box
    color: $COLOR_TEXT
    border-bottom: 4px solid transparent
    flex-direction: column
    transform: 0.3s ease
    color: #888
    &.current
      color: $COLOR_PRIMARY
      border-color: $COLOR_PRIMARY
      opacity: 1
    &:active
      background-color: rgba($COLOR_PRIMARY, 0.1)
    &:clicked
      background-color: rgba($COLOR_PRIMARY, 0.1)
    &:hover
      text-decoration: none
  .icon
    height: 24px
    margin-bottom: 4px
    display: flex
    position: relative
    align-items: center
    &.sensor
      bottom: -1px
    &.menu
      bottom: -2px
  .label
    font-weight: bold
    font-size: 12px
    line-height: 1

.wrapSimple
  z-index: 100
  width: 100%
  padding: 0 17px
  height: 56px
  background-color: #fff
  position: fixed
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  box-sizing: border-box
  display: flex
  align-items: center

.offsetSimple
  height: 56px

.headerCloseButton
  width: 30px
  line-height: 1
  position: relative
  z-index: 1
  img
    width: 30px
.linkArea
  display: block
    
.headerLogo
  margin-left: -30px
  text-align: center
  font-size: 25px
  line-height: 1
  color: #fff
  flex: 1
  font-weight: bold