@import "../../Variants.sass"
.routerBody
  margin: 0 17px
  padding: 4px 5px 8px 5px
  background-color: #FFF
  border-radius: 12px
  display: flex
  flex-direction: column
  box-shadow: 0px 0px 7px rgba(#000, 0.2), 0px 2px 3px rgba(#000, 0.1)
  & + &
    margin-top: 12px
  &.inactive
    background-color: #D8D8E2
    color: #828389

.routerIconWrap
  display: flex
  align-items: center
  margin-left: 6px

.routerStatusWrap
  display: flex
  align-items: center
  line-height: 1
  padding: 0px 7px
  height: 40px
  box-sizing: border-box
  border-radius: 5px
  background-color: #f5f5f5
  position: relative
  @media screen and (max-width: 370px)
    height: 36px

.routerStatusUpdatingWrap
  display: flex
  align-items: center
  justify-content: center
  row-gap: 15px
  position: absolute
  top: 0
  left: 0
  border: 3px solid $COLOR_TEXT_WHITE
  border-radius: 5px
  background-color: rgba(#047DED, 0.95)
  color: $COLOR_TEXT_WHITE
  height: 100%
  width: 100%

.routerStatusUpdatingText
  font-size: 23px
  font-weight: bold
  margin-left: 15px

.routerStatusUpdateTime
  font-size: 28px
  font-weight: bold
  flex: 1
  @media screen and (max-width: 370px)
    font-size: 24px

.routerIcon
  width: 46px
  margin: 0 4px -4px 0
  position: relative
  top: 1px
  & > img
    width: 100%

.routerName
  flex: 1
  font-size: 28px
  font-weight: bold
  margin-right: 12px
  line-height: 1.2
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.routerOptions
  display: flex
  align-items: center
  color: rgba($COLOR_TEXT, 0.8)
  
.routerOption
  width: 33px

.routerOptionButton
  width: 60px

.connectedSensors
  display: flex
  align-items: center
  margin-left: 4px
  &.notconnected
    opacity: 0.5
.sensorCount
  background: #eeeeee
  font-weight: bold
  color: #444
  width: 30px
  border-radius: 4px
  text-align: center
  font-size: 17px
  margin-left: 2px

.routerStatus
  display: flex
  align-items: center
  font-size: 20px
  font-weight: bold
  color: #197E44
  &.error
    color: $COLOR_NEGATIVE
    & > .routerStatusIcon
      background-color: $COLOR_NEGATIVE

.routerStatusIcon
  content: ""
  width: 15px
  height: 15px
  background-color: #197E44
  border-radius: 50px
  @media screen and (max-width: 370px)
    width: 13px
    height: 13px

.routerStatusText
  margin-left: 6px
  font-size: 26px
  @media screen and (max-width: 370px)
    font-size: 22px
    margin-left: 4px

