@import "../Variants.sass"
.wrap
  position: relative
  width: 100%
.values
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(145px, 1fr)),
  grid-gap: 14px 5px
  counter-reset: rank
  width: 100%
  @media screen and (max-width: 360px)
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr)),

.valueWrap
  display: flex
  align-items: center
  margin: 0 2px
  height: 52px
  &.alert
    background-color: #D30101
    border-radius: 4px
    border: 2px solid #fff !important
  @media screen and (max-width: 360px)
    height: 46px

.value
  display: flex
  color: $COLOR_TEXT_WHITE
  align-items: baseline
  padding: 2px 2px
  border-radius: 4px
  .number
    font-size: 64px
    font-family: $FONT_NUMBER
    font-weight: 600
    text-shadow: 0px 0px 4px rgba(#000, 0.25)
    line-height: 1
    @media screen and (max-width: 360px)
      font-size: 58px
      
  .unit
    margin-left: 3px
    font-size: 28px
    font-weight: bold
    line-height: 1
    text-shadow: 0px 0px 4px rgba(#000, 0.25)
    @media screen and (max-width: 360px)
      font-size: 24px

.signal
  display: flex
  align-items: center
  .icon
    display: flex
    align-items: center
  .icon img
    height: 45px
    @media screen and (max-width: 340px)
      height: 40px
  .label
    line-height: 1
    margin-left: 6px
    font-size: 23px
    color: $COLOR_TEXT_WHITE
    font-weight: bold
    @media screen and (max-width: 340px)
      font-size: 21px

.acceleration
  margin-top: 2px

.deviceUnavailable
  position: absolute
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  font-weight: bold
  color: $COLOR_TEXT_WHITE
  font-size: 20px

.illuminanceUnitK
  margin: 0 1px 0 3px
  box-shadow: 0px 0px 4px rgba(#000, 0.25)

.illuminanceUnitKOver
  height: 38px
  margin: 0 -11px 0 3px

.statusIconWrap
  display: flex
  align-items: center

.statusIcon
  display: flex
  align-items: center
  img
    height: 40px
  & + &
    margin-left: 12px