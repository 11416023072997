@import "../../Variants.sass"
.wrap
  margin-bottom: 15px

.body
  background-color: #fff
  border-radius: 10px
  border: 2px solid #5d3a9c

.alertCategoryMaintenance,
.alertCategoryError,
.alertCategoryNotice
  display: inline-block
  padding: 2px 7px
  color: #ffffff
  border-radius: 4px
  font-weight: bold
  background: #5d3a9c
  margin-bottom: 6px

.alertCategoryMaintenance
  background-color: #00bcd4

.alertCategoryNotice
  background-color: #ed6c00

.alert
  padding: 12px
  & + &
    border-top: 1px solid $COLOR_BORDER

.alertDate
  color: rgba($COLOR_TEXT, 0.8)

.alertMessage
  font-size: 17px
  font-weight: bold
  margin-bottom: 3px

.list + .list
  margin-top: 60px

.divider
  height: 1px
  background-color: #D4D4D4
  margin: 50px 0 30px 0

.dividerTitle
  padding: 0 17px
  font-size: 24px
  color: $COLOR_TEXT
  margin-bottom: 20px
  font-weight: bold
