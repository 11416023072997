@import "../Variants.sass"
.icon
  display: inline-block
  text-align: center
  & > img
    width: auto
    height: 27px
  & > .label
    margin-top: 1px
    font-size: 15px
    font-weight: bold