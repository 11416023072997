@import "../Variants.sass"

.checkoutArea
  display: flex
  justify-content: space-between
  line-height: 1.3
  align-items: center
  margin-top: 15px

.checkoutDetail
  display: flex
  justify-content: space-between
  line-height: 1

.checkoutTotalTitle
  font-size: 18px
  font-weight: bold

.checkoutTotalPrice
  font-size: 19px
  font-weight: bold
  color: $COLOR_PRIMARY
  text-align: right

.checkoutTotalTax
  font-size: 14px
  font-weight: bold
  color: $COLOR_PRIMARY
  text-align: right

.checkoutTotalTaxDetail
  margin-left: 6px

.shippingFeeArea
  display: flex
  align-items: center

.shippingFeeDetailArea
  display: flex
  align-items: center
  margin-left: 10px

.shippingFeeText
  font-size: 15px
  color: rgba($COLOR_TEXT, 0.7)

.shippingFeeIcon
  margin-right: 5px
  color: #C4C4C4
