@import "../../Variants.sass"

.wrap
  height: 100%
  display: flex
  flex-direction: column
.header
  height: 64px
  padding: 0 15px
  display: flex
  align-items: center
  box-shadow: 0 0 6px rgba(#000,0.1)
  z-index: 1000
.body
  flex: 1
  overflow-y: auto
.closeButton
  margin-bottom: 15px

.monitoringConditions
  position: relative
  padding: 35px 13px 35px 13px
  background-color: $COLOR_PRIMARY
  color: #fff
  &.specify
    background-color: #0C9AE6

.conditionSettingButton
  -webkit-appearance: none
  appearance: none
  display: inline-block
  font-size: 22px
  height: 48px
  line-height: 44px
  border: 2px solid rgba(#000,0.2)
  padding: 0 20px 0 7px
  color: $COLOR_TEXT
  background-color: #fff
  border-radius: 6px
  font-weight: bold
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  background-image: url(/assets/images/icon-arrow-open.png)
  background-repeat: no-repeat
  background-position: right 6px center
  background-size: 10px
  & + &
    margin-left: 7px
  &.conditionSettingTerm
    margin-top: 8px
  &.selectButtonNone
    background-image: none
    padding: 0 7px 0 7px

.conditionLabel
  font-size: 22px
  font-weight: bold
  margin: 0 5px

.destination
  display: flex
  align-items: center
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  padding: 9px 15px
  border: 2px solid #ddd
  border-radius: 6px
  box-sizing: border-box
  color: rgba($COLOR_TEXT, 0.8)
  &.active
    border-color: $COLOR_POSITIVE
    color: $COLOR_TEXT
  & + &
    margin-top: 8px

.destinationAdd
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  padding: 24px 15px
  border: 2px solid #ddd
  border-radius: 6px
  box-sizing: border-box
  text-align: center
  color: rgba($COLOR_TEXT, 0.6)
  font-size: 19px
  font-weight: bold
  line-height: 1

.destinationIcon
  width: 46px
  margin-right: 12px

.destinationContent
  flex: 1
  min-width: 0

.destinationTitle
  font-size: 20px
  font-weight: bold
  line-height: 1

.destinationDetail
  font-size: 16px
  line-height: 1
  margin-top: 5px
  overflow: hidden
  text-overflow: ellipsis


.targetSensor
  display: flex
  align-items: center
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  padding: 9px 15px
  border: 2px solid #ddd
  border-radius: 6px
  &.active
    border-color: $COLOR_POSITIVE
    color: $COLOR_TEXT
  & + &
    margin-top: 10px

.targetSensorIcon
  width: 46px
  margin-right: 12px

.targetSensorContent
  flex: 1

.targetSensorTitle
  font-size: 20px
  font-weight: bold
  line-height: 1

.targetSensorDetail
  font-size: 16px
  line-height: 1
  margin-top: 5px

.targetSensorNote
  font-size: 16px
  font-weight: bold
  color: $COLOR_NEGATIVE
  margin-top: 4px

.targetSensorSelector
  display: flex
  align-items: center
  margin-bottom: 12px

.targetSensorSelectorButton
  padding: 4px 12px
  border: 1px solid $COLOR_BORDER
  border-radius: 44px
  & + &
    margin-left: 8px

.selectingButton
  border: 1px solid rgba(203, 203, 203, 1)
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  padding: 15px
  border-radius: 6px
  & + &
    margin-top: 12px

.selectingButtonTitle
  font-size: 24px
  font-weight: bold
  color: $COLOR_PRIMARY
  &.immediately
    color: $COLOR_PRIMARY
  &.specify
    color: #0C9AE6

.selectingButtonText
  margin-top: 8px
  font-size: 16px
  strong
    font-weight: bold
    margin-bottom: 2px

.action
  box-shadow: 0px -1px 1px 0px rgba(0,0,0,0.15)
  
.notificationFeeArea
  padding: 21px 17px

.notificationDailyFee
  text-align: center

.notificationDailyFeeLabel
  font-size: 17px
  font-weight: bold

.notificationDailyFeeValue
  display: flex
  justify-content: center
  align-items: center
  margin-top: 4px
  font-weight: bold

.dailyFeeValuePrice
  font-size: 28px

.dailyFeeValueText
  font-size: 18px
  margin-left: 5px

.notificationDailyFeeDetail
  margin-top: 13px
  font-size: 17px
  display: flex
  align-items: center
  justify-content: center
  background-color: #F3F3F3
  border-radius: 6px
  padding: 5px 10px
  font-weight: bold
  position: relative
  &:before
    content: ""
    position: absolute
    top: -16px
    left: 50%
    margin-left: -15px
    border: 6px solid transparent
    border-bottom: 10px solid #F3F3F3
  @media screen and (max-width: 370px)
    font-size: 15px
    padding: 5px
    img
      position: relative
      top: 2px

.dailyFeeDetailContent
  & + &
    margin-left: 12px
    @media screen and (max-width: 370px)
      margin-left: 6px

.dailyFeeDetailValue
  color: $COLOR_PRIMARY

.notificationDailyFeeButton
  margin-top: 20px

.notificationMonthlyTotallFee
  margin-left: 10px

.notificationMonthlyFeeDetail
  margin-bottom: 19px

.notificationDeleteButtonWrap
  margin-top: 27px

.notificationDeleteButton
  font-size: 19px
  color: $COLOR_NEGATIVE
  padding: 7px 22px
  border: 1px solid rgba($COLOR_TEXT,0.1)
  border-radius: 100px
  max-width: 220px
  margin: 0 auto
  text-align: center

.monitoringFee
  text-align: center
  font-weight: bold
  font-size: 24px

.monitoringFeeWrap
  margin-top: 12px
  display: flex
  align-items: center
  justify-content: center
  font-weight: bold
  strong
    font-size: 36px
    margin-right: 5px
    display: block
  s
    opacity: 0.7

.monitoringFeeValue
  font-size: 36px

.monitoringFeeLabel
  font-size: 24px
  margin-left: 4px
  position: relative
  top: 3px

.monitoringFeeTitle
  margin-top: 12px
  text-align: center
  font-weight: bold   
  line-height: 1.5
  @media screen and (max-width: 340px)
    font-size: 15px
    margin-top: 12px

.monitoringFeeNote
  text-align: left
  margin-top: 16px
  height: 140px
  font-size: 14px
  font-weight: bold
  line-height: 1.4
  padding: 10px 12px
  background-color: #F4F6F5
  border: 1px solid $COLOR_BORDER
  border-radius: 7px
  overflow: auto
  @media screen and (max-width: 340px)
    height: 102px
    margin-top: 10px

.buttonArea
  margin-top: 23px

.detailButtonIcon
  margin-right: 10px

.usageFeeDetailTextWrap
  font-size: 14px
  margin-top: 7px

.usageFeeDetailText
  color: $COLOR_NEGATIVE

.detailButtonIcon
  &.close
    transform: rotate(180deg)
    margin-top: 2px

.usageFeeDetail
  color: $COLOR_TEXT
  border-bottom: solid 1px $COLOR_TEXT
  display: inline-block

.usageFeeAreaWrap
  margin-top: 17px

.usageFeeDetailWrap
  & + &
    margin-top: 27px

.usageFeeDetailTitle
  font-size: 17px
  font-weight: bold
  background-color: #F8F8F8
  padding: 6px 0
  text-align: center

.systemUsageFeeWrap
  margin-top: 18px

.systemUsageFeeArea
  display: flex
  justify-content: space-between
  margin-top: 12px
  line-height: 1.4
  .name
    flex: 1
    text-align: left
  .price
    text-align: right
    margin-left: 12px
    width: 70px

.systemUsageFeeStrong
  color: $COLOR_PRIMARY
  font-weight: bold

.monitoringForm
  & + &
    margin-top: 15px

.monitoringFormLabel
  font-size: 20px
  color: #fff
  margin-bottom: 4px

.monitoringFormWrap
  border-radius: 6px
  &.lists
    background-color: rgba(#fff,0.18 )
    padding: 12px 10px
    .monitoringFormBody
      padding-bottom: 10px
      border-bottom: 1px solid rgba(#fff,0.3 )
      display: flex
      align-items: center
      justify-content: space-between
      & + &
        margin-top: 10px

.monitoringFormBody
  display: flex
  align-items: center
  &.gutterTop
    margin-top: 7px
  &.specificTime
    flex-direction: column
    align-items: start

.monitoringTimeButton
  display: flex
  align-items: center

.conditionSettingWrap
  display: flex
  align-items: center
  flex: 1

.deleteButtonWrap
  width: 26px

.deleteTimeButtonWrap
  margin-left: 12px

.monitoringFormDeleteButton
  cursor: pointer

.monitoringFormAddButton
  display: inline-flex
  align-items: center
  font-size: 19px
  font-weight: bold
  border: 2px solid #F5BF92
  box-shadow: 0px 1px 4px rgba(#000, 0.15)
  border-radius: 7px
  padding: 6px 10px 6px 6px
  box-sizing: border-box
  cursor: pointer
  margin-top: 12px
  &.specify
    background-color: #39acea
    border: 2px solid #74c5f0
.conditionSettingArea
  display: flex
  flex-wrap: wrap
  margin: -2px 0

.conditionSettingValue
  display: flex
  align-items: center
  margin: 2px 0
      
.monitoringTelChan
  position: absolute
  bottom: -30px
  right: 20px

.warning
  padding: 15px
  border-radius: 4px
  font-size: 19px
  background-color: rgba($COLOR_NEGATIVE, 0.1)
  font-weight: bold
  color: $COLOR_NEGATIVE
  margin-bottom: 12px