@import "../../Variants.sass"

.headerLogo
  text-align: center
  margin-bottom: 24px

.inputProfileTitle
  margin-bottom: 24px

.passwordDetailText
  margin-top: 4px
  margin-bottom: 4px

.action
  margin-top: 28px

.errorText
  font-size: 20px
  color: $COLOR_NEGATIVE
  line-height: 1.4

.errorButton
  margin-top: 40px
