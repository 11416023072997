@import "../Variants.sass"
.wrap
  background-color: #fff
  border-radius: 15px
  box-shadow: 0px 0px 13px rgba(#000, 0.12)
  &.basic
    border: 1px solid $COLOR_BORDER
    box-shadow: none
    border-radius: 7px
    padding: 15px 15px
  &.gutterBottom
    margin-bottom: 20px
  &.notPadding
    padding: 0