.wrap
  & + &
    margin-top: 16px

.label
  font-size: 16px
  font-weight: bold
  margin-bottom: 2px
  display: flex
  align-items: center

.required
  background-color: #ff0000
  line-height: 1
  padding: 2px 5px
  border-radius: 2px
  color: #fff
  margin-left: 6px
  font-size: 14px

.contents
  display: flex
  align-items: center

.error
  color: #f00
  margin-top: 4px
  font-weight: bold