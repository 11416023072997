@import "./Variants.sass"

// Reset
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline
  
body
  position: relative
  min-height: 100vh
  font-size: 16px
  background-color: #FFF
  color: $COLOR_TEXT
  line-height: 150%
  -webkit-text-size-adjust: 100%
  -webkit-font-smoothing: antialiased
  font-family: -apple-system, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif
  &.showing-modal
    overflow: hidden

@for $i from 1 through 10
  .margin-top-#{$i}x
    margin-top: #{4 *$i}px !important
  .margin-bottom-#{$i}x
    margin-bottom: #{4 *$i}px !important
  .margin-left-#{$i}x
    margin-left: #{4 *$i}px !important
  .margin-right-#{$i}x
    margin-right: #{4 *$i}px !important

footer
  text-align: center
  font-size: 10px
  color: #656565

  img
    margin-top: 8px
    margin-left: 40px

  span
    float: right
    font-size: 18px
    font-weight: bold
    margin-top: 12px
    color: #fff
    margin-left: 8px

  hr
    width: 2px
    height: 33px
    margin-top: 6px
    margin-bottom: 0px
    background-color: #fff

.App
  background-color: '#FAF9F5'
  opacity: 1.0

.App-logo
  height: 40vmin
  pointer-events: none

@media (prefers-reduced-motion: no-preference)
  .App-logo
    animation: App-logo-spin infinite 20s linear

.App-header
  background-color: #282c34
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: calc(10px + 2vmin)
  color: white

.App-link
  color: #61dafb

@keyframes App-logo-spin
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)

.App-fadeout
  color: #FFFFFF
  text-align: center
  -webkit-transition: opacity 1s ease-in-out
  -moz-transition: opacity 1s ease-in-out
  -ms-transition: opacity 1s ease-in-out
  -o-transition: opacity 1s ease-in-out
  opacity: 0

.App-hidden
  display: none

.App-show
  display: block
  margin: 0
  padding: 0
  min-height: 10px

.App-top
  margin-top: 40px
  width: 100%
  margin-left: auto
  margin-right: auto
  text-align: center

.Top-content
  padding-top: 36px

.Top-input-Area
  margin-bottom: 15px
  margin-left: 15px
  margin-right: 15px

.Top-input
  width: 100%
  box-sizing: border-box
  font-size: 24px
  border-radius: 5px
  border: solid 1px #989898
  height: 50px
  padding: 10px

.Top-list
  list-style-type: none
  padding: 0
  margin-left: 20px
  margin-right: 20px

  li
    padding-left: 1em
    text-indent: -.7em
    font-size: 16px

    &:before
      content: "● "
      color: #45BAA3

.Button-Area
  text-align: center
  margin-left: 60px
  margin-right: 60px

.Big-button
  height: 60px
  font-size: 26px
  font-weight: bold
  color: #ffffff
  box-sizing: border-box
  width: 100%
  border-radius: 10px
  border: none

.Button-long
  color: #fff
  height: 44px
  border-radius: 24px
  width: 280px
  text-align: left
  font-size: 20px
  font-weight: bold
  padding-left: 10px
  border: none

  img
    height: 25px
    margin-right: 10px

.Button-round
  padding-left: 20px
  padding-right: 20px
  height: 38px
  border-radius: 18px
  color: #fff
  font-weight: bold
  border: none

.Button-orange
  background: linear-gradient(to bottom, #F2B33B 0, #D93328 90%, #BE3A34 100%)

.Button-green
  background: linear-gradient(to bottom, #19C4B0, #146A56)

.Button-grey
  background: linear-gradient(to bottom, #C7C1C1, #4E4D4D)

.Triangle-line
  background-color: #FFCFB1
  margin-bottom: 0
  margin-left: 15px
  margin-right: 15px
  height: 2px

.Down-Arrow-Triangle
  width: 0
  height: 0
  border-left: 20px solid transparent
  border-right: 20px solid transparent
  border-top: 20px solid #FFCFB1
  margin-left: auto
  margin-right: auto

.Green-box
  margin-left: 10px
  margin-right: 10px
  padding-top: 20px
  padding-bottom: 20px
  border: solid 2px #45BAA3

.White-box
  background-color: #fff
  padding-top: 20px
  padding-left: 15px
  padding-right: 15px

.Detail-text
  margin-left: 40px
  margin-right: 40px

.Top-Stepper
  margin: 0
  padding: 0
  height: 30px
  width: 100%
  background-color: #CFE6E6

  p
    color: #36AF97
    font-size: 16px
    margin-left: 16px
    padding-top: 2px
    font-weight: bold

  div
    margin: 0
    padding: 0
    height: 30px
    position: absolute
    right: 22px
    top: 100px

    ul
      margin: 0
      padding: 0
      color: #fff
      font-size: 22px
      font-weight: bold
      list-style-type: none

      li
        padding-left: 15px
        padding-right: 15px
        padding-bottom: 4px
        height: 30px
        display: inline

.Top-Stepper-active
  background-color: #45BAA3

.Corner-container
  display: flex

.Left-corner
  text-align: left
  flex: 1

.Left-corner-small
  text-align: left
  flex: 0.5

.Right-corner
  text-align: right
  flex: 1

.Right-corner-small
  text-align: right
  flex: 0.5

.Required
  width: 50px
  position: absolute
  right: 15px
  text-align: center
  height: 25px

  p
    font-size: 16px
    background-color: #E53222
    border-radius: 3px
    color: #fff
    margin-bottom: 0px

.Error
  color: red
  font-weight: normal

.Down-arrow
  display: inline-block
  width: 0
  height: 0
  margin-left: .255em
  vertical-align: .255em
  content: ""
  border-top: .3em solid
  border-right: .3em solid transparent
  border-bottom: 0
  border-left: .3em solid transparent

.Alert-message-header
  background-color: #E53222
  color: #fff
  font-size: 20px
  font-weight: bold

.Alert-title
  width: 120px
  text-align: center
  padding-top: 5px
  padding-bottom: 5px
  border-radius: 5px
  font-weight: bold
  color: #fff

.Alert-title-orange
  background-color: #E67504

.Alert-title-green
  background-color: #45BAA3

.Device-area
  border-top: solid 1px #989898
  padding-top: 15px
  padding-bottom: 15px
  position: relative

.Router-area-selected
  background: linear-gradient(to bottom, #FFFFFF, #45BAA3)

  &:before
    content: ""
    position: absolute
    top: 100%
    left: 50%
    margin-left: -30px
    z-index: 2
    border: 30px solid transparent
    border-top: 30px solid #45BAA3

.Device-area-selected
  background: linear-gradient(to bottom, #FFFFFF, #D0CECE)

  &:before
    content: ""
    position: absolute
    top: 100%
    left: 50%
    margin-left: -30px
    z-index: 2
    border: 30px solid transparent
    border-top: 30px solid #D0CECE

.Device-name-date
  display: flex
  margin-left: 10px
  margin-right: 10px
  margin-bottom: 15px

.Device-name
  font-size: 22px
  font-weight: bold
  flex: 0.7
  text-align: left

.Device-date
  flex: 0.3
  text-align: right

.Content-with-footerbar
  margin-bottom: 60px

.Device-sensor
  display: flex
  margin-left: 15px
  margin-right: 15px

.Device-sensor-value
  text-align: left
  flex: 0.7

  img
    margin-right: 10px

.Device-rssi
  text-align: right
  flex: 0.3

.Device-sensor-text
  font-size: 44px
  position: relative
  top: 16px

.Device-sensor-suffix
  font-size: 22px
  margin-left: 6px
  position: relative
  top: 16px

.Edit-area
  background-color: #FFF8D9
  padding-top: 32px

.Edit-area-hide, .Edit-area-cover-hide
  display: none

.Edit-area-cover
  position: absolute
  background-color: #fff
  top: 0
  margin: 0
  padding: 0
  bottom: 0
  opacity: 0.8
  height: 100%
  width: 100%
  z-index: 7

.Edit-box
  font-weight: bold
  margin-left: 0
  margin-right: 0
  background-color: #fff
  border: solid 8px #707070

.Edit-box-editing
  margin-left: 10px
  margin-right: 10px
  background-color: #FFF8D9
  border: none

.Edit-key
  width: 95px
  margin-top: 10px
  margin-bottom: 10px

.Info-title
  margin-left: 5px
  color: #36AF97
  font-size: 18px
  font-weight: bold

.Info-text
  font-size: 16px
  font-weight: bold

.Alert-container
  margin-left: 15px
  margin-right: 15px
  margin-top: 40px

.Alert-container-list
  margin-top: 0px

.Alert-header
  border: solid 1px red

.Alert-header-list
  border: none

.Alert-hr

.Footer-menu
  background-color: #E67504
  height: 100%
  width: 0
  position: fixed
  z-index: 10
  top: 0
  left: 0
  overflow-x: hidden
  padding-top: 30px
  transition: 0.1s
  color: #fff
  padding-right: 40px

  a
    padding: 8px 8px 8px 32px
    text-decoration: none
    font-size: 25px
    color: #fff
    font-weight: bold
    display: block
    transition: 0.3s

  p
    padding: 6px 6px 6px 30px
    text-decoration: none
    font-size: 25px
    color: #fff
    font-weight: bold
    display: block
    transition: 0.3s

.Footer-hr-fill
  background-color: #F0B078
  height: 1px

.Footer-hr
  background-color: #F0B078
  height: 1px
  margin-left: 30px
  margin-top: 0

.Footer-down-arrow
  transform: rotate(45deg)
  -webkit-transform: rotate(45deg)

.Footer-list
  display: flex

.Footer-list-left
  flex: 1
  text-align: left

.Footer-list-right
  flex: 1

  i
    border: solid white
    border-width: 0 3px 3px 0
    display: inline-block
    padding: 3px

.Footer-detail
  margin-left: 40px
  margin-bottom: 14px

.Footer-bg
  background-color: #000
  height: 100%
  width: 100%
  position: fixed
  z-index: 9
  top: 0
  left: 0
  overflow-x: hidden
  opacity: 0.7

.center
  text-align: center

.bold
  font-weight: bold

.mtop40
  margin-top: 40px

.mtop30
  margin-top: 30px

.mtop20
  margin-top: 20px

.lr20
  margin-left: 20px
  margin-right: 20px

.Title-text-green
  margin-left: 20px
  color: #36AF97
  font-size: 28px
  font-weight: bold

.Title-text-hr
  margin-top: 15px
  margin-bottom: 20px
  margin-left: 15px
  margin-right: 15px
  height: 4px
  background-color: #45BAA3
  border: none

.textbold
  font-weight: bold

.text22
  font-size: 22px

.text20
  font-size: 20px

.text-green
  color: #36AF97

.switch-area
  margin-right: 5px

  img
    width: 60px

.Guide-box
  line-height: 140%
  border-radius: 8px
  margin-left: 10px
  margin-right: 10px
  margin-top: 10px
  font-size: 24px
  font-weight: bold
  color: #2E2E2E
  background-color: #EFFDFB
  border: solid 2px #45BAA3

.Guide-opened
  border-bottom-left-radius: 0px
  border-bottom-right-radius: 0px

.Guide-title
  padding-left: 20px
  padding-right: 20px

.Guide-box p
  margin-top: 1rem

.left
  text-align: left

.Guide-right
  position: relative
  text-align: right

  div
    margin: 0
    position: absolute
    top: 50%
    right: 10px
    -ms-transform: translateY(-50%)
    transform: translateY(-50%)

.Guide-answer
  padding-bottom: 20px
  border-top: solid 2px #45BAA3
  background-color: #fff
  padding-left: 20px
  padding-right: 20px

.Guide-detail-number
  border-radius: 3px
  text-align: center
  width: 34px
  height: 34px

.Guide-detail-number-orange
  background-color: #F27B32
  color: #fff

.Guide-detail-number-green
  border-radius: 5px
  color: #45BAA3
  background-color: #fff
  border: solid 1px #45BAA3
  width: 32px
  height: 32px
  font-size: 20px

.Guide-detail-text
  float: left

.Guide-green
  color: #36AF97

.Guide-detail-text
  font-weight: normal

.hide
  display: none

.show
  display: block

.Guide-arrow-down
  color: #45BAA3
  transform: rotate(90deg)

.custom-form
  height: 46px
  border-radius: 4px
  border: solid 1px #989898
  padding: 4px
  font-weight: bold
  padding-left: 8px
  background-color: #fff

.Green-line
  background-color: #45BAA3

.Green-area
  background-color: #EFFDFB

.Green-text
  color: #36AF97

.text
  margin-bottom: 0

.right
  text-align: right

.double
  height: 2px
