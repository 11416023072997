@import "../../Variants.sass"
.header
  padding: 30px 17px 0 17px
  display: flex
  align-items: center
  flex-direction: column
  .sensorImage

  .name
    font-size: 24px
    font-weight: bold
    word-break: break-all
    text-align: center
  .serialNumber
    font-size: 14px
    margin-top: 3px
  .button
    margin-top: 8px

.viewWrap
  position: relative
  height: 100%
  display: flex
  flex-direction: column

.viewHeader
  height: 64px
  padding: 0 15px
  display: flex
  align-items: center
  box-shadow: 0 0 6px rgba(#000,0.1)
  z-index: 1000

.viewBody
  flex: 1
  overflow-y: auto

.deviceInformation
  margin-bottom: 30px

.deviceStatus
  display: flex
  align-items: center
  padding: 15px
  justify-content: center
  .status
    display: flex
    flex-direction: column
    flex: 1
    align-items: center
    max-width: 140px

.deviceDetails
  .values
    display: flex
    justify-content: center
    background-color: #197E44
    padding: 15px 15px
    border-radius: 6px
.warning .deviceDetails .values
  background-color: #DAB700

.alert
  color: $COLOR_TEXT
  text-align: center
  padding: 17px 0 12px 0
  .alertTitle
    font-size: 24px
    font-weight: bold
    margin-bottom: 4px
  .alertDescription
    font-size: 16px
    font-weight: bold
  .alertNoteImage
    margin-top: 8px
    img
      width: 240px
  .alertNote
    margin-top: 5px
    font-size: 14px
    line-height: 1.4
    color: darken($COLOR_ALERT, 35%)

.actions
  margin-top: 12px

.notification
  padding: 15px
  border-radius: 7px
  background-color: #EDEDED
  color: rgba($COLOR_TEXT, 0.5)
  &.immediate
    background-color: #fff
    color: $COLOR_PRIMARY
    border: 3px solid $COLOR_PRIMARY
    .notificationSubText
      color: $COLOR_TEXT
  &.specifiedTime
    background-color: #fff
    color: #0C9AE6
    border: 3px solid #0C9AE6
  & + &
    margin-top: 15px
.notificationText
  font-size: 22px
  line-height: 1.3
  font-weight: bold

.notificationSubText
  font-size: 19px
  margin-top: 7px

.notificationTag
  padding: 5px 13px 5px 10px
  margin-bottom: 7px
  line-height: 1
  border-radius: 4px
  background-color: rgba(#0C9AE5, 0.1)
  display: inline-flex
  align-items: center
  font-size: 18px
  .text
    margin-left: 6px

.notificationButtonWrap
  margin-top: 22px
  text-align: center
  &.notificationButtonSmallWrap
    margin-top: 18px

.notificationSettingWrap
  text-align: center
.notificationSettingTitle
  font-size: 20px
  font-weight: bold
  margin-top: 18px
.notificationSettingText
  margin-top: 10px
  font-size: 18px

.deviceHistoryWrap
  padding: 0px 17px 40px 17px
  
.deviceHistory
  display: flex
  
.historyLabel
  white-space: nowrap
  min-width: 65px

.historyValue
  flex: 1
  word-break: break-all

.deviceName
  margin-top: 8px

.helpMessage
  margin-bottom: 18px

.showMoreArea
  padding: 20px 0
  width: 100%
  text-align: center
  background-color: #f5f5f5
  border-radius: 11px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column

.showMoreButton
  margin: 0 auto
  display: flex
  align-items: center
  background-color: #FFFFFF
  justify-content: center
  padding: 0 24px
  box-sizing: border-box
  height: 34px
  border-radius: 120px
  color: rgba($COLOR_TEXT ,0.8)
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15)
  cursor: pointer
  border: 0
  &:disabled
    opacity: 0.3
  &:hover
    background-color: #eee
  &.currentButton
    margin: 0 0 0 5px

.showMoreText
  margin-left: 3px
  font-weight: bold
  font-size: 18px
