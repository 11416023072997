@import "../Variants.sass"
.wrap
  display: inline-block
  position: relative
  width: 100%

.control
  width: 100%
  height: 53px
  font-size: 21px
  background-color: #fff
  color: $COLOR_TEXT
  outline: none
  border: none
  border: 2px solid #AFAFAF
  padding: 0 10px 0 10px
  border-radius: 4px
  box-sizing: border-box
  appearance: none
  cursor: pointer
  transition: 0.5s ease
  border-radius: 5px

  &:disabled
    background: #ccc
    color: #bbb
  &:hover
    border-color: #888888
  &:focus
    border-color: $COLOR_PRIMARY
  
  &.large
    height: 62px
    font-size: 28px

  &::placeholder
    color: #bbb

.error .control
  border-color: #f00
  color: #f00

.errorMessage
  color: #f00
  font-size: 17px
  font-weight: bold
  margin-top: 3px
  padding: 3px 8px
  border-radius: 4px
  background-color: rgba(#f00, 0.1)

.disabledStyle
  background: #ddd
  color: #666
  pointer-events: none