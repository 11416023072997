@import "../../Variants.sass"
.text
  font-size: 19px
  margin-top: 2px

.warningMessageWrap
  margin-top: 20px

.warningMessage
  display: flex
  padding: 20px
  border-radius: 7px
  background-color: #eee
  & + &
    margin-top: 13px

.warningMessageIcon
  margin-top: 7px
  widht: 33px
  height: 33px
  img
    width: 100%

.warningMessageContents
  flex: 1
  line-height: 1.4
  margin-left: 7px
  font-size: 19px

.warningMessageTitle
  font-weight: bold
  line-height: 1.4
  color: $COLOR_NEGATIVE

.warningMessageText
  margin-top: 4px

.tips
  padding: 20px
  border-radius: 7px
  margin-top: 13px
  border: 1px solid $COLOR_BORDER
  font-size: 19px
  line-height: 1.4

.tipsTitle
  font-weight: bold

.tipsText
  margin-top: 4px

.deviceButton
  margin-top: 10px

.action
  margin-top: 25px
  text-align: center

.buttonWrap
  margin-top: 15px