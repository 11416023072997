@import "../Variants.sass"
.wrap
  margin-top: 20px

.title
  font-size: 17px
  font-weight: bold

.text
  margin-top: 2px

.detailButton
  color: $COLOR_PRIMARY
  text-decoration: underline
  cursor: pointer

.checkBox
  margin-top: 6px
  background-color: #F6F6F6
  border-radius: 3px
  padding: 13px
  box-sizing: border-box

.modalWrap
  display: flex
  flex-direction: column
  height: 100%

.modalHeader
  z-index: 100
  width: 100%
  padding: 0 17px
  height: 56px
  background-color: #fff
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  box-sizing: border-box
  display: flex
  align-items: center

.headerCloseButton
  width: 30px
  line-height: 1
  position: relative
  z-index: 1
  img
    width: 30px

.modalBody
  padding: 26px 17px 100px 17px
  flex: 1
  overflow: auto