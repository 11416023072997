@import "../Variants.sass"
.backtoTop
  height: 80px
  display: flex
  align-items: center
  justify-content: center
  background-color: #484a4c

.backButton
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
.backButtonIcon
  width: 0
  height: 0
  border-style: solid
  border-width: 0 8px 12px 8px
  border-color: transparent transparent rgba(#fff, 0.9) transparent
.backButtonText
  font-weight: bold
  color: rgba(#fff, 0.9)
  font-size: 15px
  margin-top: 4px

.footer
  padding: 45px 30px
  background-color: #292B2D

.links
  text-align: center

.link
  font-size: 13px
  text-decoration: none
  color: rgba(#fff, 0.8)
  & + &
    margin-left: 20px

.copyright
  margin-top: 3px
  text-align: center
  font-size: 12px
  color: rgba(#fff, 0.7)

.navigation
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  flex: 1
  padding: 20px 0
  text-decoration: none

.navigationIcon img
  width: 35px
  height: 35px

.navigationText
  font-weight: bold
  color: $COLOR_TEXT
  font-size: 17px

.navigationDivider
  height: 80px
  background-color: $COLOR_BORDER
  width: 1px