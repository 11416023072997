@import "../Variants.sass"

.heading1
  font-weight: bold
  font-size: 28px
  line-height: 1.4
  &.gutterBottom
    margin-bottom: 18px

.heading2
  font-weight: bold
  font-size: 24px
  line-height: 1.4
  &.gutterBottom
    margin-bottom: 18px
  &.gutterTop
    margin-top: 40px

.heading3
  font-weight: bold
  font-size: 21px
  line-height: 1.4
  &.gutterBottom
    margin-bottom: 18px
  &.gutterTop
    margin-top: 40px

.heading4
  font-weight: bold
  font-size: 17px
  line-height: 1.8
  &.gutterBottom
    margin-bottom: 15px
  &.gutterTop
    margin-top: 30px

.paragraph
  font-size: 16px
  &.gutterBottom
    margin-bottom: 15px
  &.gutterTop
    margin-top: 20px

.note
  font-size: 14px
  line-height: 1.4
  &.gutterBottom
    margin-bottom: 15px
  &.gutterTop
    margin-top: 20px

.ex
  font-size: 12px
  line-height: 1.4
  &.gutterBottom
    margin-bottom: 15px
  &.gutterTop
    margin-top: 20px


.center
  text-align: center

.wrap strong
  font-weight: bold