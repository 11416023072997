@import "../../Variants.sass"

.headerLogo
  text-align: center
  margin-bottom: 24px

.inputMailAddressTitle
  margin-bottom: 24px

.action
  margin-top: 28px

.inputMailAddressText
  margin-top: 20px
  font-size: 15px
  a
    color: $COLOR_PRIMARY

.backPageButton
  text-align: center
  margin: 19px 0 47px
.backPageButtonColor
  color: $COLOR_TEXT
  border-bottom: solid 1px $COLOR_TEXT

.confirmMailText
  margin-bottom: 15px

.confirmMailImage
  text-align: center
  margin-bottom: 24px

.confirmMailDetailText
  font-size: 20px
  line-height: 1.4
  word-break: break-all
  & > strong
    font-weight: bold

.confirmMailNote
  margin-top: 19px
  border-radius: 4px
  font-size: 13px
  padding: 9px 10px
  border: 1px solid $COLOR_BORDER
  line-height: 1.5
  a 
    color: $COLOR_PRIMARY

.confirmMailNoteTitle
  font-weight: bold
  line-height: 1.4
  margin-bottom: 2px