@import "../Variants.sass"
.phoneNumberSignin
  border-radius: 15px
  border: 2px solid #E7E7E7
  padding: 20px 23px
  box-sizing: border-box

.signinTitle
  font-weight: bold
  font-size: 20px
  text-align: center

.signinText
  margin-top: 7px
  text-align: center

.signinButtonWrap
  margin-top: 10px

.signinButton
  display: flex
  align-items: center
  justify-content: center

.buttonIcon
  height: 30px
  position: relative
  top: 1px

.buttonText
  margin: 0 2px 0 4px