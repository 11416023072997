@import "../../Variants.sass"
.header
  padding: 20px 17px 30px 17px
  display: flex
  align-items: center
  flex-direction: column
  border-bottom: 1px solid $COLOR_BORDER
  .image
    width: 170px
    & > img
      max-width: 100%
  .name
    font-size: 24px
    font-weight: bold
    word-break: break-all
  .serialNumber
    font-size: 14px
    margin-top: 3px
  .button
    margin-top: 8px

.viewWrap
  position: relative
  height: 100%
  display: flex
  flex-direction: column

.viewHeader
  height: 64px
  padding: 0 15px
  display: flex
  align-items: center
  box-shadow: 0 0 6px rgba(#000,0.1)
  z-index: 1000

.viewBody
  flex: 1
  overflow-y: auto

.sensorLists
  padding: 20px 17px
  .lists
    width: 100%
  .list
    display: flex
    align-items: center
    align-content: space-between
    padding: 5px 0
  .list + .list
    border-top: 1px solid #ccc
  .icon
    width: 42px
    margin-right: 5px
    img
      width: 100%
  .name
    flex: 1
    font-size: 19px
    font-weight: bold
  .action
    margin-left: 8px
    width: 80px
    text-align: right

.sensorListsBlank
  text-align: center
  & > .image
    margin: 15px 0 4px 0
    img
      width: 100px
      opacity: 0.3
  & > .title
    font-size: 17px
    font-weight: bold
    color: rgba($COLOR_TEXT, 0.8)
  & > .text
    margin-top: 8px
    font-size: 15px
    color: rgba($COLOR_TEXT, 0.8)

.actions
  margin-top: 12px

.routerStatus
  padding: 15px
  background-color: #F2F2F2
  border: 2px solid #8B8B8B
  border-radius: 4px
  font-size: 18px
  font-weight: bold
  line-height: 1.5
  margin-top: 25px
  width: 100%
  &.nomal
    color: #197E44
    border-color: #197E44
    background-color: #DFEDE5
  &.error
    border-color: $COLOR_NEGATIVE
    color: $COLOR_NEGATIVE
    background-color: #FFF5F5

.routerResetImage
  display: flex
  margin: 15px auto 0 auto
  width: 220px
  text-align: center

.inactiveText
  margin-top: 12px
  color: $COLOR_TEXT
  font-weight: 400
  font-size: 14px

