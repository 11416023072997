@import "../../Variants.sass"

.purchaseNumberArea
  display: flex
  align-items: center
  border: solid 1px #AFAFAF
  border-radius: 7px
  justify-content: space-around
  width: 160px
  height: 44px
  margin-top: 8px
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
.purchaseNumberInputIcon
  width: 44px
  height: 44px
  display: flex
  align-items: center
  justify-content: center
  font-weight: bold
  text-align: center
.purchaseNumberInput
  flex: 1
  height: 43px
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  font-weight: bold
  font-size: 21px
  border-left: solid 1px #AFAFAF
  border-right: solid 1px #AFAFAF
.purchaseNumberLabel
  font-size: 15px
  margin-left: 1px
  position: relative
  top: 1px

.deviceExplainArea
  margin-top: 20px
  padding-top: 12px
  display: flex
  align-items: center
  border-top: solid 1px #EBEBEB
  line-height: 1.5
.deviceExplainText
  font-size: 13px
  padding-right: 20px
.warningText
  color: #0F8DE8

.confirmTextArea
  color: $COLOR_NEGATIVE
  padding: 13px
  border: solid 2px $COLOR_NEGATIVE
  font-weight: bold
  margin: 20px 0 36px
.confirmRouterTitle
  font-size: 18px
  margin-bottom: 5px
.confirmRouterDetail
  font-size: 14px

.checkoutNote
  font-size: 13px
  margin-top: 10px
  a
    color: $COLOR_PRIMARY

.divider
  height: 1px
  background-color: $COLOR_BORDER
  margin: 20px 0

.action
  margin-top: 28px
.wrapSimple
  z-index: 100
  width: 100%
  padding: 0 17px
  height: 56px
  background-color: #fff
  position: fixed
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  box-sizing: border-box
  display: flex
  align-items: center

.offsetSimple
  height: 56px

.headerCloseButton
  width: 30px
  line-height: 1
  position: relative
  z-index: 1
  img
    width: 30px

.modalBody
  padding: 26px 17px 100px 17px
  height: 100vh
  overflow: auto

.modalTitle
  font-size: 17px
  font-weight: bold

.modalTextWrap
  margin-top: 15px
  font-size: 15px
  & + &
    margin-top: 13px

.modalTextTitle
  font-weight: bold

.modalText
  margin-top: 4px