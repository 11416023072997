@import "../../Variants.sass"

.component
  background-color: #197E44
  border-radius: 12px
  color: $COLOR_TEXT_WHITE
  padding: 8px 5px
  box-sizing: border-box
  & + &
    margin-top: 12px
  &.inactive
    background-color: #D8D8E2
    color: #828389  

.deviceNameWrap
  margin-left: 8px
  font-size: 28px
  line-height: 1.1
  font-weight: bold
  position: relative

.deviceName
  font-size: 22px
  line-height: 1.2
  display: flex
  align-items: center

.deviceNameDetail
  margin-left: 7px

.deviceDetailWrap
  margin-top: 6px
  background-color: #0D5B2E
  border-radius: 5px
  padding: 11px 9px 5px 9px
  position: relative

.deviceDetail
  display: flex
  align-items: center
  justify-content: space-between
  padding-bottom: 7px
  border-bottom: 1px solid rgba(#fff,0.1)
  margin-bottom: 12px

.timeWrap
  display: flex
  height: 32px
  align-items: center

.time
  font-size: 30px
  font-weight: bold
  color: rgba($COLOR_TEXT_WHITE, 0.9)
  flex: 1
  display: flex
  align-items: center
  line-height: 1
  &.notUpToDate
    color: #EDC804
  
.detailTime
  margin-left: 8px

.updateButton
  display: flex
  align-items: center
  cursor: pointer
  padding: 0px 4px 1px 0px
  box-sizing: border-box
  background: #256B43
  border: 1px solid #B4D1C0
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15)
  border-radius: 2px
  &:hover
    opacity: 0.7

.updateButtonText
  line-height: 1
  font-size: 21px
  font-weight: bold
  margin-left: -1px

.backButton
  display: flex
  width: 100px
  margin: 30px auto 0px
  align-items: center
  text-align: center
  cursor: pointer
  padding: 2px 5px 2px 15px
  box-sizing: border-box
  border: 2px solid #fff
  border-radius: 120px
  &:hover
    opacity: 0.7

.backButtonText
  line-height: 1
  font-size: 18px
  font-weight: bold
  margin-left: -1px

.deviceStatusWrap
  position: absolute
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  border: 3px solid $COLOR_TEXT_WHITE
  border-radius: 5px
  background-color: rgba(#047DED, 0.95)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  &.alert
    background-color: rgba(#EDC804, 0.95)

.deviceStatusText
  font-size: 23px
  line-height: 1
  font-weight: bold
  text-align: center

.deviceStatusTextDetail
  margin-top: 5px
  font-weight: normal
  font-size: 18px
  padding: 0 12px
  line-height: 1.3
  @media screen and (max-width: 657px)
    margin-top: 10px

.monitoringSettingWrap
  margin-top: 5px
  display: flex
  align-items: center
  background: #219151
  padding: 3px 8px
  border-radius: 5px
  &.alert
    background-color: #FF0C0C
  & + &
    margin-top: 3px

.iconWrap
  color: rgba($COLOR_TEXT_WHITE, 0.8)

.settingValues
  line-height: 1
  margin-left: 6px
  color: rgba($COLOR_TEXT_WHITE, 0.9)
  
.settingValue
  font-weight: bold
  line-height: 1.1
  & + &
    margin-left: 7px

.alertWrap
  background-color: #FF0C0C
  border: 2px solid #fff
  border-radius: 7px
  padding: 6px 10px 10px
  box-sizing: border-box
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1)

.iconWrap
  color: rgba($COLOR_TEXT_WHITE, 0.8)
 
.alertTitle
  line-height: 1
  margin-left: 8px
  color: rgba($COLOR_TEXT_WHITE, 0.9)

.alertText
  margin-top: 4px
