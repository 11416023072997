@import "../Variants.sass"

.progressIndicator
  text-align: center
  &.gutter
    margin: 80px 0
  &.fit
    display: flex
    align-items: center
    justify-content: center
    height: 100%
  & > .loader
    animation: loadingFadeIn 0.9s ease 0s 1 forwards
    display: inline-block
    opacity: 0
    .spinner
      animation: loadingRotation 1.35s linear infinite
      stroke: $COLOR_PRIMARY
      width: 65px
      height: 65px
      &.small
        width: 30px
        height: 30px
      &.xSmall
        width: 27px
        height: 27px
      &.xxSmall
        width: 22px
        height: 22px
    .circle
      stroke-dasharray: 180
      stroke-dashoffset: 0
      transform-origin: center
      animation: loadingTurn 1.35s ease-in-out infinite

// Animation
@keyframes loadingFadeIn
  20%
    opacity: 0
  100%
    opacity: 0.8

@keyframes loadingRotation
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(270deg)

@keyframes loadingTurn
  0%
    stroke-dashoffset: 180

  50%
    stroke-dashoffset: 45
    transform: rotate(135deg)

  100%
    stroke-dashoffset: 180
    transform: rotate(450deg)
