@import "../Variants.sass"
.imageWrap
  display: flex
  justify-content: center
  .successImage
    height: 96px
  .errorIcon
    height: 80px

.title
  margin-top: 20px

.detailText
  margin-top: 8px

.button
  margin-top: 24px