@import "../../Variants.sass"
.paymentMethod
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15)
  padding: 19px 13px
  border: 2px solid #ddd
  border-radius: 15px
  box-sizing: border-box
  cursor: pointer
  &.active
    border: 3px solid $COLOR_PRIMARY
  &.active .paymentSelectButton
    border-color: $COLOR_PRIMARY
  &.active .paymentSelectButton::after
    background-color: $COLOR_PRIMARY
  & + &
    margin-top: 8px

.paymentSelectArea
  display: flex

.paymentSelectAreaLeft
  width: 24px
  margin-right: 8px

.paymentSelectAreaRight
  flex: 1
.paymentSelectButton
  display: block
  text-decoration: none
  position: relative
  width: 24px
  height: 24px
  border-radius: 50%
  box-sizing: border-box
  border: solid 2px $COLOR_BORDER
  &::after
    content: ''
    display: block
    position: absolute
    top: 3px
    left: 3px
    width: 14px
    height: 14px
    background-color: transparent
    border-radius: 50%

.paymentMethodTitle
  font-size: 21px
  font-weight: bold
  .sub
    font-size: 16px

.paymentDetailArea
  margin-top: 20px
  margin-bottom: 30px

.creditCardCompanyArea
  display: flex
  align-items: center
  flex-wrap: wrap
  margin: 8px 0 20px

.creditCardCompany
  & + &
    margin-left: 11px

.creditCardFieldWrap
  display: flex
  align-items: center

.creditCardField
  display: flex
  align-items: center
  & + &
    margin-left: 21px

.creditCardUnit
  margin-left: 10px
  font-weight: bold
  font-size: 20px

.creditCvcArea
  display: flex
  justify-content: flex-end
  align-items: center
  margin-top: 10px
.cvcText
  font-size: 12px
  font-weight: bold
  margin-left: 5px
.creditSaveText
  font-size: 12px
  line-height: 1.5
  margin-top: 18px

.securityCodeText
  text-align: center
  font-size: 13px
  margin-bottom: 30px
.securityCodeImage
  margin: 0 auto
  margin-bottom: 13px
.creditBackside
  margin-right: 13px

.backPageButton
  cursor: pointer
  text-align: center
  margin: 19px 0 47px
.backPageButtonColor
  color: $COLOR_TEXT
  border-bottom: solid 1px $COLOR_TEXT

.confirmPaymentdetail
  font-size: 13px
  margin-top: 10px
  a
    color: $COLOR_PRIMARY

.action
  margin-top: 40px

.divider
  height: 1px
  background-color: $COLOR_BORDER
  margin: 20px 0

.paymentMethodArea
  font-size: 18px
  strong
    font-weight: bold
.creditCardImage
  line-height: 1
  box-sizing: border-box
  margin-top: 10px

.paymentMethodDetailText
  margin-top: 17px
  strong
    color: $COLOR_PRIMARY

.carrierBillingText
  font-size: 14px
  font-weight: bold
  margin-bottom: 12px

.link
  color: $COLOR_PRIMARY
  cursor: pointer

.invoicePaymentDescription
  padding: 12px
  background-color: #F2F2F2
  margin-top: 12px
  border-radius: 7px