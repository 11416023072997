@import "../Variants.sass"
.wrap
  display: inline-block
  position: relative
  width: 100%

.control
  width: 100%
  font-size: 17px
  background-color: #fff
  color: $COLOR_TEXT
  outline: none
  border: none
  border: 2px solid #AFAFAF
  padding: 12px 10px 12px 10px
  border-radius: 4px
  box-sizing: border-box
  appearance: none
  cursor: pointer
  transition: 0.5s ease
  border-radius: 5px

  &:disabled
    background: #ccc
    color: #bbb
  &:hover
    border-color: #888888
  &:focus
    border-color: $COLOR_PRIMARY

.error .control
  border-color: #f00
  color: #f00