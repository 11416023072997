@import "../../Variants.sass"

.headerLogo
  text-align: center
  margin-bottom: 24px

.passwordResetTitle
  margin-bottom: 24px

.action
  margin-top: 28px

.passwordResetText
  margin-top: 20px
  font-size: 15px

.backPageButton
  text-align: center
  margin: 19px 0 47px

.backPageButtonColor
  color: $COLOR_TEXT
  border-bottom: solid 1px $COLOR_TEXT

.passwordResetSentTitle
  margin-bottom: 15px

.passwordResetSentImage
  text-align: center
  margin-bottom: 24px

.passwordResetSentText
  font-size: 20px
  line-height: 1.4
  padding: 0 10px
  word-break: break-all
  & > strong
    font-weight: bold
    margin-right: 4px

.errorText
  font-size: 20px
  color: $COLOR_NEGATIVE
  line-height: 1.4
