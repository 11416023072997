@import "../Variants.sass"
.alertMessageArea
  display: flex
  align-items: center
  background-color: rgba($COLOR_NEGATIVE, 0.05)
  padding: 15px
  border: 1px solid $COLOR_NEGATIVE
  border-radius: 7px
  &.gutterBottom
    margin-bottom: 13px
.alertImage
  img
    width: 37px

.alertText
  color: $COLOR_NEGATIVE
  font-weight: bold
  margin-left: 12px
  font-size: 17px
  line-height: 1.4
