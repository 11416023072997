@import "../Variants.sass"

.freePriceTextWrap
  margin-top: 8px

.freePriceText
  font-weight: bold

.button
  margin-top: 24px
