@import "../Variants.sass"
.component
  margin-top: 8px
  display: flex
  align-items: center
  justify-content: space-between
  padding: 7px 10px
  box-sizing: border-box
  background-color: #F5F5F5
  border-radius: 5px
  @media screen and (max-width: 340px)
    padding: 2px 5px

.routerImage
  height: 45px

.networkImage
  height: 45px

.wrap
  flex: 1
  display: flex
  flex-direction: column
  align-items: center
  color: $COLOR_POSITIVE
  margin: 0 8px
  &.weak
    color: #EDAB00
    .status
      background-color: #EDAB00
  &.unstable
    color: $COLOR_ALERT
    .status
      background-color: $COLOR_ALERT

.title
  font-size: 10px
  font-weight: bold
  line-height: 1

.status
  content: ""
  width: 100%
  height: 3px
  min-height: 3px
  flex: 1
  background-color: $COLOR_POSITIVE
  margin-top: 4px
  border-radius: 10px

.text
  line-height: 1
  margin-top: 4px
  font-size: 12px
  font-weight: bold


